
export const formatNodeProjectToProject = (node) => {
  if (!node) return null;
  const project = node?.data
  if (!project || !project.name.text) return null
  const uid = node?.uid && node.uid.length > 0 ? node.uid : null
  if (!uid) return null
  const tags = project.tags.raw && project.tags.raw.length > 0 ? project.tags.raw.map((tag) => tag.text) : []
  const client = project?.client?.document?.data || null
  return {
    uid,
    tags,
    client,
    year: project.year,
    name: project.name,
    has_page: project.has_page,
    has_nda: project.has_nda
  }
}
